body {
  margin: 0;
  padding: 0;
}

/* width */
::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ff824c;
  /* border-radius: 2px; */
}

.pattern {
  background-color: #fff;
  background-image: radial-gradient(#ff824c 0.5px, transparent 0.5px),
    radial-gradient(#ff824c 0.5px, #fff 0.5px);
  background-size: 20px 20px;
  background-position: 0 0, 10px 10px;
}
